// Base colors
// Shades
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #2d2d2d !default;
$gray-1000: #121212 !default;
$black: #191919 !default;

// Colors
$blue: #2b6cdb !default;
$darkblue: #1c3663 !default;
$indigo: #6610f2 !default;
$purple: #8e24aa !default;
$pink: #d63384 !default;
$red: #b50909 !default;
$orange: #db8700 !default;
$yellow: #db8700 !default;
$green: #348500 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
$transparent: transparent !default;

// Brand colors
$brand: #f9af00 !default;
$brand-secondary: darken($brand, 10%) !default;
$brand-contrast: $black;
$brand-secondary-contrast: $white;

// Semantic colors
$primary: $brand !default;
$primary-contrast: $brand-contrast !default;
$primary-level-2: lighten($brand, 5%) !default;
$primary-level-2-contrast: $white !default;
$primary-selected: darken($primary, 15%);
$primary-hover: darken($primary, 10%);
$secondary: $gray-500 !default;
$secondary-contrast: $gray-600;
$secondary-level-2: darken($brand, 10%) !default;
$secondary-level-2-contrast: $white;
$success: $green !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;
$inactive: #47565e;
$far-away: #acb9ff;
$accent: $brand;
$accent-contrast: $black;

//===== Definitions =====//

//===== App =====//
$app-base: $gray-200;
$app-base-contrast: $black;
$app-base-secondary: $gray-500;
$app-base-secondary-contrast: $black;
$app-base-subtitle: $gray-700;
$app-base-text-color: $black;
$app-base-secondary-text-color: $black;
$app-base-subtitle-text-color: $black;

//===== Navbar =====//
$navbar-background-color: $white;
$navbar-text-color: $black;

//===== Lateral Navbar =====//
$lateral-navbar-icons-color: $black;

//===== Sidebar =====//
$sidebar-background-color: $navbar-background-color;
$sidebar-text-color: $navbar-text-color;

// Brand logo
$brand-logo-background: $white;

// Custom colors

$custom-app-base: $app-base;
$custom-app-base-secondary: $app-base-secondary;
$custom-app-base-text-color: $app-base-text-color;
$custom-lateral-navbar-icons-color: $lateral-navbar-icons-color;
$custom-slide-panel-content-background-color: $app-base;
$custom-remote-table-title-background-color: $primary;
$custom-remote-table-title-text-color: $primary-contrast;
$custom-remote-table-body-background-color: $gray-200;
$custom-remote-table-body-text-color: $gray-800;
$custom-remote-table-pagination-unactive-link-text-color: $gray-500;
$custom-revision-panel-filter-container-background-color: $app-base-secondary;
$custom-dark-marker-popup-title-background-color: $app-base;
$custom-dark-marker-popup-content-background-color: $app-base-secondary;
$custom-filter-panel-background-color: $app-base-secondary;
$custom-expandable-panel-title-background-color: $app-base;
$custom-expandable-panel-title-text-color: $app-base-contrast;
$custom-expandable-panel-subtitle-background-color: $app-base-secondary;
$custom-expandable-panel-subtitle-text-color: $app-base-secondary-contrast;
$custom-input-base-background-color: $transparent;
$custom-group-panel-background-color: $app-base-secondary;
$custom-group-panel-text-color: $app-base-text-color;
$custom-group-panel-action-text-color: $black;
$custom-tab-base-background-color: #00102b;
$custom-input-popper-background-color: #424242;
$custom-input-popper-text-color: $white;

//===== Tab panel =====//
$tab-base-background-color: $custom-tab-base-background-color;
$tab-base-text-color: $white;
$tab-selected-background-color: $transparent;
$tab-selected-text-color: $white;
$tab-subtitle-background-color: $primary;
$tab-subtitle-text-color: $white;

//===== Login page =====//
$login-screen-background-color: $app-base-secondary;
$login-container-background-color: $white;
$login-container-text-color: $black;
$login-container-input-background-color: $transparent;
$login-container-input-text-color: $app-base-text-color;
$login-container-input-label-color: $app-base-text-color;
$login-container-input-focused-label-color: $app-base-text-color;
$login-screen-background-image: "";
